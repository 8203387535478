<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(async () => {});
</script>

<template>
  <v-layout>
    <v-container fill-height fluid>
      <v-row>
        <v-col cols="12"> HOME VIEW </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<style lang="scss" scoped></style>
