import axios from "axios";
import router from "../router";
import type { App } from "vue";
import { useNotification } from "@kyvg/vue3-notification";
import { useAuthStore } from "@/stores/auth";

interface AxiosOptions {
  baseUrl?: string;
  token?: string;
}

export default {
  install: (app: App, options: AxiosOptions) => {
    const authStore = useAuthStore();
    const { notify } = useNotification();

    axios.defaults.baseURL = options.baseUrl;
    axios.defaults.headers.common["x-access-token"] = options.token;

    authStore.$subscribe((mutation, state) => {
      axios.defaults.headers.common["x-access-token"] =
        state.user?.token ?? null;
    });

    axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        if (
          (error.response.status === 403 || error.response.status === 401) &&
          router.currentRoute.value.name !== "login"
        ) {
          notify({
            title: "Not authorized",
            text: "You are not logged in or your session has ended",
            type: "error",
          });
          await router.push("/login");
        }

        if (error.response.status === 500) {
          notify({
            title: "Something went wrong",
            text: error.response.data.error,
            type: "error",
          });
        }
      }
    );

    app.config.globalProperties.$axios = axios;
  },
  setAccessToken: (app: App, token: string) => {
    axios.defaults.headers.common["x-access-token"] = token;
  },
};
