import { createApp } from "vue";
import { createPinia } from "pinia";

import "@mdi/font/css/materialdesignicons.css";
import "./assets/main.scss";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import { VDataTableServer } from "vuetify/labs/VDataTable";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import Notifications from "@kyvg/vue3-notification";

import axios from "./plugins/axios";
import piniaResetPlugin from "./plugins/pinia-reset";

import App from "./App.vue";
import router from "./router";

import createAuthMiddleware from "@/middleware/auth";
import createSpinnerMiddleware from "@/middleware/spinner";

const app = createApp(App);
const pinia = createPinia();

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTableServer,
  },
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#61adda",
          "primary-light-1": "#2caacf",
          "primary-light-2": "#3dd1fd",
          "primary-dark-1": "#0c576d",
          "primary-dark-2": "#094151",
          "primary-grey": "#aad7f3",
          secondary: "#24968c",
          "secondary-light-1": "#2fbeb1",
          "secondary-light-2": "#3eeddd",
          "secondary-dark-1": "#1d7c74",
          "secondary-dark-2": "#135751",
          white: "#ffffff",
          black: "#000000",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

app.use(pinia);
app.use(router);

app.use(axios, {
  baseUrl: import.meta.env.VITE_MONGO_ADMIN_API_ENDPOINT,
});

app.use(vuetify);
app.use(Notifications, { componentName: "Notifications" });

pinia.use(piniaResetPlugin);

Promise.all([createAuthMiddleware(), createSpinnerMiddleware()]).then(() => {
  app.mount("#app");
});
