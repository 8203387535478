import { ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { omit } from "lodash";
import { notify } from "@kyvg/vue3-notification";

export type CmoiwItemType = {
  _id: string;
  number: string;
  material: string;
  description: string;
  cas: string;
  manufacturer: string;
  extra_info: string;
};

export type CmoiwDataType = {
  items: CmoiwItemType[];
  count: number;
};

export type CmoiwSortOrderType = "asc" | "desc";

export type CmoiwSortByType = {
  key: keyof CmoiwItemType;
  order: CmoiwSortOrderType;
};

export type CmoiwMetaType = {
  itemsPerPage: number;
  page: number;
  sortBy: CmoiwSortByType[];
  search: string;
};

const defaultSortBy: CmoiwSortByType[] = [
  {
    key: "number",
    order: "asc",
  },
];

const baseUrl = "/cmoiw";

export const useCmoiwStore = defineStore("cmoiw", () => {
  const meta = ref<CmoiwMetaType>({
    itemsPerPage: 50,
    page: 1,
    sortBy: defaultSortBy,
    search: "",
  });
  const data = ref<CmoiwDataType>({
    items: [],
    count: 0,
  });
  const selectedItem = ref<CmoiwItemType>({
    _id: "",
    number: "",
    material: "",
    description: "",
    cas: "",
    manufacturer: "",
    extra_info: "",
  });
  const items = computed(() => data.value.items);
  const count = computed(() => data.value.count);
  const search = computed(() => meta.value.search);

  async function getItems() {
    const { data: newData } = await axios.get<CmoiwDataType>(baseUrl, {
      params:
        meta.value.sortBy.length > 0
          ? meta.value
          : {
              ...meta.value,
              sortBy: defaultSortBy,
            },
    });
    data.value = {
      ...data.value,
      ...newData,
    };
  }

  async function getItem(id: CmoiwItemType["_id"]) {
    const { data: newData } = await axios.get<CmoiwItemType>(
      `${baseUrl}/${id}`
    );
    selectedItem.value = newData;
  }

  async function removeItems(items: CmoiwItemType["_id"][]) {
    await axios.delete(baseUrl, {
      data: {
        ids: items,
      },
    });
    notify({
      title: "Success",
      text: `Item(s) removed successfully`,
      type: "success",
    });
  }

  async function createItem(item: Omit<CmoiwItemType, "_id">) {
    await axios.post<Omit<CmoiwItemType, "_id">, CmoiwItemType>(baseUrl, {
      data: item,
    });
    notify({
      title: "Success",
      text: `Item added to CMOiW database`,
      type: "success",
    });
  }

  async function insertMany(file: File) {
    await axios.post<File, File>(
      `${baseUrl}/batch`,
      {
        data: { file },
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    notify({
      title: "Success",
      text: `Items imported into CMOiW database`,
      type: "success",
    });
  }

  async function updateItem(item: CmoiwItemType) {
    const { data: newData } = await axios.put<
      Omit<CmoiwItemType, "_id">,
      { data: CmoiwItemType }
    >(`${baseUrl}/${item._id}`, {
      data: omit(item, "_id"),
    });
    selectedItem.value = newData;
    data.value.items = data.value.items.map((el) =>
      el._id === newData._id ? newData : el
    );
    notify({
      title: "Success",
      text: `Item updated successfully`,
      type: "success",
    });
  }

  function resetSelectedItem() {
    selectedItem.value = {
      _id: "",
      number: "",
      material: "",
      description: "",
      cas: "",
      manufacturer: "",
      extra_info: "",
    }
  }

  return {
    items,
    selectedItem,
    count,
    meta,
    data,
    search,
    getItems,
    getItem,
    removeItems,
    createItem,
    insertMany,
    updateItem,
    resetSelectedItem,
  };
});
