import router from "@/router";
import { useAuthStore } from "@/stores/auth";

export default async function createAuthMiddleware() {
  const authStore = useAuthStore();

  try {
    await authStore.userinfo();
  } catch (err) {
    await router.push("/login");
  }

  router.beforeEach(async (to, from) => {
    if (!authStore.loggedIn && to.name !== "login") {
      await router.push("/login");
    }
  });
}
