import { ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { useNotification } from "@kyvg/vue3-notification";
import router from "@/router";

export type UserCredentialsType = {
  username: string;
  password: string;
};

export type UserType = {
  username: string;
  email: string;
  token: string | null;
  exp?: number;
  iat?: number;
};

export const useAuthStore = defineStore("auth", () => {
  const { notify } = useNotification();

  const user = ref<UserType | null>(null);
  const username = computed(() => user.value?.username ?? "");
  const email = computed(() => user.value?.email ?? "");
  const loggedIn = computed(() => !!user.value);

  async function login({ username, password }: UserCredentialsType) {
    try {
      const { data } = await axios.post("/auth/login", { username, password });
      user.value = data;
      localStorage.setItem("access_token", data.token);
      notify({
        title: "Logged in",
        text: "Welcome to admin panel for tusnovics.pl mongoDB",
        type: "success",
      });
    } catch (e: any) {
      notify({
        title: "Invalid credentials",
        text: "Username or password are invalid",
        type: "error",
      });
    }
  }

  async function userinfo() {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;
      const { data } = await axios.get("/auth/user", {
        headers: {
          "x-access-token": token,
        },
      });
      user.value = {
        ...data,
        token,
      };
      notify({
        title: "Welcome back",
        text: "Happy to see you again :)",
        type: "success",
      });
    } catch (e: any) {
      notify({
        title: "Your session expired",
        text: "Unfortunately, your previous session has ended, please log in again",
        type: "error",
      });
    }
  }

  async function logout() {
    localStorage.removeItem("access_token");
    user.value = null;
    await router.push("/login");
    notify({
      title: "Logged out",
      text: "Your session has ended",
      type: "success",
    });
  }

  return { user, loggedIn, login, logout, userinfo, username, email };
});
