<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

const props = defineProps<{
  username: string;
  email: string;
}>();

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
</script>

<template>
  <v-app-bar class="app-bar">
    <router-link to="/" class="app-bar__logo">
      <img
        alt="Tusnovics logo"
        class="app-bar__logo-img"
        src="@/assets/logo.png"
      />
    </router-link>
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon icon="mdi-dots-vertical" />
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item :title="props.username" :subtitle="props.email">
            </v-list-item>
          </v-list>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="text" @click="$emit('logout')">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<style scoped lang="scss">
.app-bar {
  padding: 6px 12px;
  position: relative;
  z-index: 9002 !important;
}

.app-bar__logo {
  display: block;
  height: 50px;

  &-img {
    display: block;
    height: 100%;
  }
}
</style>
