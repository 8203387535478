<script lang="ts" setup></script>

<template>
  <div class="app-loader d-flex justify-center align-center fill-height">
    <v-progress-circular indeterminate color="primary" size="70" width="8">
    </v-progress-circular>
  </div>
</template>

<style lang="scss" scoped>
.app-loader {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9001;
  background-color: rgba(255, 255, 255, 0.6);
}
</style>