import { computed, ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { omit } from "lodash";
import { notify } from "@kyvg/vue3-notification";

export type GearItemType = {
  _id: string;
  name: string;
  description: string;
  norm: string[];
  method: string[];
  manufacturer: string;
  url: string;
  catalogs: string[];
  video: string;
  pdf: string;
  image: string;
  category: string[];
};

export type GearItemWithImageFileType = Omit<GearItemType, "image"> & {
  image: File;
};

export type GearDataType = {
  items: GearItemType[];
  count: number;
};

export type GearSortOrderType = "asc" | "desc";

export type GearSortByType = {
  key: keyof GearItemType;
  order: GearSortOrderType;
};

export type CmoiwMetaType = {
  itemsPerPage: number;
  page: number;
  sortBy: GearSortByType[];
  search: string;
};

const defaultSortBy: GearSortByType[] = [
  {
    key: "name",
    order: "asc",
  },
];

const baseUrl = "/gear";

const createFileFromUrl = (url: string) => {
  return new File([new Blob()], url);
};

export const useGearStore = defineStore("gear", () => {
  const meta = ref<CmoiwMetaType>({
    itemsPerPage: 50,
    page: 1,
    sortBy: defaultSortBy,
    search: "",
  });
  const data = ref<GearDataType>({
    items: [],
    count: 0,
  });
  const selectedItem = ref<GearItemType>({
    _id: "",
    name: "",
    description: "",
    norm: [],
    method: [],
    manufacturer: "",
    url: "",
    catalogs: [],
    video: "",
    pdf: "",
    image: "",
    category: [],
  });
  const items = computed(() => data.value.items);
  const count = computed(() => data.value.count);
  const search = computed(() => meta.value.search);
  const imageRef = ref<File | null>(null);
  const pdfRef = ref<File[]>([]);

  async function getItems() {
    const { data: newData } = await axios.get<GearDataType>(baseUrl, {
      params:
        meta.value.sortBy.length > 0
          ? meta.value
          : {
              ...meta.value,
              sortBy: defaultSortBy,
            },
    });
    data.value = {
      ...data.value,
      ...newData,
    };
  }

  async function getItem(id: GearItemType["_id"]) {
    const { data: newData } = await axios.get<GearItemType>(`${baseUrl}/${id}`);
    selectedItem.value = newData;

    if (newData.image) {
      imageRef.value = createFileFromUrl(newData.image);
    }

    if (newData.pdf) {
      const file = createFileFromUrl(newData.pdf);
      pdfRef.value = [file];
    }
  }

  async function removeItems(items: GearItemType["_id"][]) {
    await axios.delete(baseUrl, {
      data: {
        ids: items,
      },
    });
    notify({
      title: "Success",
      text: `Item(s) removed successfully`,
      type: "success",
    });
  }

  async function createItem(item: Omit<GearItemType, "_id">) {
    await axios.post<
      Omit<GearItemWithImageFileType | GearItemType, "_id">,
      GearItemType
    >(
      baseUrl,
      {
        data: {
          ...item,
          image: imageRef.value ?? item.image,
          pdf: pdfRef.value.length > 0 ? pdfRef.value[0] : item.pdf,
        },
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    notify({
      title: "Success",
      text: `Item added to Aparatura database`,
      type: "success",
    });
  }

  async function updateItem(item: GearItemType) {
    const imageUpdated = imageRef.value?.name !== item.image;
    const pdfUpdated = pdfRef.value?.[0]?.name !== item.pdf;

    const { data: newData } = await axios.put<
      Omit<GearItemWithImageFileType | GearItemType, "_id">,
      { data: GearItemType }
    >(
      `${baseUrl}/${item._id}`,
      {
        data: omit(
          {
            ...item,
            image: imageUpdated ? imageRef.value : item.image,
            pdf: pdfUpdated ? pdfRef.value[0] : item.pdf,
          },
          "_id"
        ),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    selectedItem.value = newData;
    data.value.items = data.value.items.map((el) =>
      el._id === newData._id ? newData : el
    );
    notify({
      title: "Success",
      text: `Item updated successfully`,
      type: "success",
    });
  }

  function resetSelectedItem() {
    selectedItem.value = {
      _id: "",
      name: "",
      description: "",
      norm: [],
      method: [],
      manufacturer: "",
      url: "",
      catalogs: [],
      video: "",
      pdf: "",
      image: "",
      category: [],
    }
  }

  return {
    items,
    selectedItem,
    count,
    meta,
    data,
    search,
    imageRef,
    pdfRef,
    getItems,
    getItem,
    removeItems,
    createItem,
    updateItem,
    resetSelectedItem
  };
});
