<script setup lang="ts">
import { RouterView } from "vue-router";
import { storeToRefs } from "pinia";
import AppBar from "@/components/AppBar.vue";
import AppLoader from "@/components/AppLoader.vue";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";

const appStore = useAppStore();
const authStore = useAuthStore();
const { username, email, loggedIn, user } = storeToRefs(authStore);
const { loading } = storeToRefs(appStore);

const handleLogout = async () => {
  await authStore.logout();
};
</script>

<template>
  <v-app full-height>
    <app-bar
      v-if="loggedIn"
      :username="username"
      :email="email"
      @logout="handleLogout"
    />

    <v-navigation-drawer
      v-if="loggedIn"
      expand-on-hover
      rail
      permanent
      class="bg-primary-grey app-navigation-drawer"
    >
      <v-list density="compact" nav class="app-drawer-list mt-6">
        <router-link
          to="/cmoiw"
          class="app-drawer-list__link text-black text-decoration-none"
        >
          <v-list-item
            prepend-icon="mdi-lock-pattern"
            value="cmoiw"
            class="text-overline"
          >
            <v-list-item-title class="text-button font-weight-bold">
              CMOiW
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          to="/gear"
          class="app-drawer-list__link text-black text-decoration-none"
        >
          <v-list-item
            prepend-icon="mdi-cog"
            value="gear"
            class="text-overline"
          >
            <v-list-item-title class="text-button font-weight-bold">
              Aparatura
            </v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-main class="d-flex mt-5">
      <AppLoader v-if="loading" />
      <RouterView />
    </v-main>
  </v-app>

  <Notifications position="bottom center" />
</template>

<style lang="scss">
.app-navigation-drawer {
  z-index: 9002;
}
</style>
