import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useCmoiwStore } from "@/stores/cmoiw";
import { useGearStore } from "@/stores/gear";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/LoginView.vue"),
    },
    {
      path: "/cmoiw",
      name: "cmoiw",
      component: () => import("../views/CmoiwView.vue"),
    },
    {
      path: "/cmoiw/create",
      name: "cmoiw-create",
      beforeEnter: () => {
        const cmoiw = useCmoiwStore();
        cmoiw.resetSelectedItem();
      },
      component: () => import("../views/CmoiwSingleView.vue"),
    },
    {
      path: "/cmoiw/insert-many",
      name: "cmoiw-insert-many",
      component: () => import("../views/CmoiwInsertMany.vue"),
    },
    {
      path: "/cmoiw/:id",
      name: "cmoiw-edit",
      component: () => import("../views/CmoiwSingleView.vue"),
    },
    {
      path: "/gear",
      name: "gear",
      component: () => import("../views/GearView.vue"),
    },
    {
      path: "/gear/create",
      name: "gear-create",
      beforeEnter: () => {
        const gear = useGearStore();
        gear.resetSelectedItem();
      },
      component: () => import("../views/GearSingleView.vue"),
    },
    {
      path: "/gear/:id",
      name: "gear-edit",
      component: () => import("../views/GearSingleView.vue"),
    },
  ],
});

/*router.beforeEach((to, from, next) => {
  appStore.setLoading(true);
  next();
});

router.afterEach((to, from) => {
  appStore.setLoading(false);
});*/

export default router;
