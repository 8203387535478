import router from "@/router";
import { useAppStore } from "@/stores/app";

export default async function createAuthMiddleware() {
  const appStore = useAppStore();

  router.beforeEach((to, from, next) => {
    appStore.setLoading(true);
    next();
  });

  router.afterEach(() => {
    appStore.setLoading(false);
  });
}
